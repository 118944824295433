@media only screen and (min-width:120px) and (max-width: 480px) {
    .Toastify__toast-container--top-center {
      top: 1em;
      left: 43%;
      transform: translateX(-50%);
      width: auto !important;
      /* width: 60% !important; */
    }
  }


