.RowCellData{
    padding: 5px !important;
    background: #EEF0FA;
}
.RowCellDataIconEdit{
    padding: 5px 10px !important;
    background: #EEF0FA;
}
.mblArrow {
    display: flex;
    justify-content: center;
    background-color: #243665;
    border-radius: 25px;
}


/* ________________*/


  .sun {
    width: 15px;
    height: 15px;
    background: -webkit-linear-gradient(to right, #22B175, #2ad68f);
    background: linear-gradient(to right, #22B175, #2ad68f);
    border-radius: 60px;
    display: inline;
    position: absolute;
  }
  
  .sunshine {
    animation: sunshines 2s infinite;
  }
  
  @keyframes sunshines {
    0% {
      transform: scale(2);
      opacity: 0.6;
    }
  
    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }

  .searchinput::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer !important;
  }
  
  

  /* _______________ */