
.iconspace{
    padding: 0px 10px;
}

.header-btn{
  height:50px;
  border-radius: 10px;
  margin-left: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #243665;
  color: #FFFFFF;
  cursor: pointer;
  /* width: 290px; */
  /* -webkit-box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important;
  -moz-box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important;
  box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important; */
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  margin-top: 5px;
  margin-bottom: 10px;
}
.header-btn:hover,.header-btn:focus,.header-btn:active {
  /* border-color: #22B175; */
  transform: scale(1.05);
}

.bulk-move-btn{  
  border-radius: 10px; 
  font-size: 14px;
  background-color: #243665;
  color: #FFFFFF;
  cursor: pointer;
  border: 3.5px solid #22B175;   
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  margin-bottom: 10px;

}
