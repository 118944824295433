.center-image-txt {
    font-size: 14px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.flx{
    display: flex;
}

.imgMobile {
    height: 30px;
}