.center-image-txt {
    margin: 6px 10px;
    font-size: 15px;
    text-transform: none;
}

.flx{
    display: flex;
    align-items: center;
}

.RowCellData{
    padding: 5px !important;
    background: #EEF0FA;
}
.tableHeadStyl{
    color: #676666 !important;
    font-size: 14px !important;
    padding: 10px !important;
    background-color:#EEF0FA !important;
    justify-content: center !important;    
    }

.typoData{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
}

    /* sx={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",maxWidth:"25%"}} */