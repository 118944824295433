.center-image-txt {
    margin: 6px 10px;
    font-size: 15px;
    text-transform: none;
}

.flx{
    display: flex;
    align-items: center;
}

.RowCellDatamanage{
    padding: 5px !important;
    background: #EEF0FA;
    white-space: nowrap !important;
}

.customRowsPerPageOptions {
    font-size: 14px !important; /* Adjust the font size as needed */
  }


  