/* .css-6hp17o-MuiList-root-MuiMenu-list{
    background-color: #EAEAEA;
} */

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 100px !important;
}

.profileLabel{
    font-size: 14px !important;
    font-weight: 400;
    color: #000000 !important;
    white-space: normal !important;
    width: 150px !important;
}

@media (max-width:2900px) and (min-width:1440px) {
    .profileLabel{
        /* font-size: 14px !important;
        font-weight: 400;
        color: #000000 !important;
        white-space: normal !important; */
        width: 200px !important;
    } 
}


@media (max-width:600px) {
    .profileLabel{
        /* font-size: 14px !important;
        font-weight: 400;
        color: #000000 !important;
        white-space: normal !important; */
        width: 100px !important;
    } 
}