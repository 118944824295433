.errorValidationTextMobile {
    font-size: 13px !important;
    left: 147px;
    line-height: 10px;
    position: absolute;
    margin-top:70px !important;
    color: #d32f2f !important;
  }

  .errorValidationTextMobilePopupBook {
    font-size: 13px !important;
    left: 150px;
    line-height: 10px;
    position: absolute;
    margin-top:74px !important;
    color: #d32f2f !important;
  }

  /* @media (min-width:427px) and (max-width: 550px) {
    .errorValidationTextMobilePopupBook {
      font-size: 13px !important;
      left: 158px;
      line-height: 10px;
      position: absolute;
      margin-top:70px !important;
      color: #d32f2f !important;
    }
}
  */

  .errorValidationTextMobileQr {
    font-size: 12px !important;
    /* left:128px; */
    height: 13px;
    line-height: 10px;
    /* text-align: left; */
    /* position: relative; */
    /* margin-top:42px !important; */
    color: red;
    z-index: 1;
  }

  .errorValidationFromTime {
    font-size: 13px !important;
    margin-left: 46px !important;
   
    line-height: 10px;
   
    color: #d32f2f;
  }

  @media (min-width: 200px) and (max-width: 900px) {
    .errorValidationFromTime {
      margin-left: 30px !important;
    } 
}

.searchinput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer !important;
}    

 