.MuiInputBase-root {
 height: 50px;
 /* width: 70px; */
 justify-content: space-between;
}

.errorValidationForgetPass {
    font-size: 13px;
    left: 5px;
    line-height: 10px;
    position: relative !important;
    margin-top:-12px !important;   
    color: red;
  }
