/* .css-6hp17o-MuiList-root-MuiMenu-list{
    background-color: #EAEAEA;
} */

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 100px !important;
} 

.helpertxt {
color:red !important ;
position: relative;
top: 15px;
left: 5px;


}
.css-k4qjio-MuiFormHelperText-root{
    color: red !important;
    font-size: 14px !important;
}
/* .css-mhc70k-MuiGrid-root>.MuiGrid-item{
    padding-top: 40px !important;
} */

.divField > div {
    padding-top: 40px !important;
}

@media (min-width: 200px) and (max-width: 800px) {
    .divField > div {
        padding-top: 30px !important;
    }
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputFullName {
      position: relative;
      bottom: 30px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelUserType {
      position: relative;
      bottom: 30px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputUserType {
        position: relative;
        bottom: 55px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputUserTypeProfileDocter {
        position: relative;
        bottom: 60px !important;
    } 
}


@media (min-width: 900px) and (max-width: 1064px) {
    .topTheLabel {
       margin-top: -10px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1083px) {
    .topTheLabel {
       margin-top: -5px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheLabelStateManageUser {
       margin-top: -6px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheLabelStateManageUserView {
       margin-top: 2px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1083px) {
    .topTheLabelStateManageUserView {
       margin-top: 2px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheLabelStateManageUserView {
       margin-top: -8px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1083px) {
    .topTheInputStateManageUserView {
       margin-top: 4px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheInputStateManageUserView {
       margin-top: -4px !important;
    } 
}


@media (min-width: 1064px) and (max-width: 1456px) {
    .topTheLabelStateManageUser {
       margin-top: -2px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheInputStateManageUser {
       margin-top: -1px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheInputStateManageUserView {
       margin-top: 3.1px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1456px) {
    .topTheInputStateManageUser {
       margin-top: -1px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheLabelStateManageUser {
       margin-top: -6px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheInputStateManageUser {
       margin-top: -5px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheDocterLabel {
      position: relative !important;
      bottom: 10px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheDocterInput {
      position: relative !important;
      bottom: 10px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterLabel {
        position:relative;
       bottom: 215px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterInput {
        position:relative;
       bottom: 240px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailLabel {
       position:relative;
       bottom: 75px !important;
    } 
}



@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailInput {
       position:relative;
       bottom: 95px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityLabel {
       position:relative;
       bottom: 95px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameLabel {
       position:relative;
       bottom: 125px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameInput {
       position:relative;
       bottom: 145px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topThePasswordLabel {
       position:relative;
       bottom: 150px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topThePasswordInput {
       position:relative;
       bottom: 185px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabel {
       position:relative;
       bottom: 195px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelInput {
       position:relative;
       bottom: 220px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelInputDoctorProfile {
       position:relative;
       bottom: 207px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelStateManageUser {
       position:relative;
       bottom: 235px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelStateManageUserView {
       position:relative;
       bottom: 220px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputStateManageUserView {
       position:relative;
       bottom: 240px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputStateManageUser {
       position:relative;
       bottom: 250px !important;
    } 
} 

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressLabelEditView {
       position:relative;
       bottom: 250px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressLabel {
       position:relative;
       bottom: 270px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressInput {
       position:relative;
       bottom: 290px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressInputEditView {
       position:relative;
       bottom: 260px !important;
    } 
}


@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityInput {
       position:relative;
       bottom: 115px !important;
    } 
}

@media (min-width: 900px) and (max-width: 996px) {
    .topTheEmailLabel {
       margin-top: -7px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterProfileIvrLabel {
       position:relative;
       bottom: 35px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterProfileIvrInput {
       position:relative;
       bottom: 60px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserTypeProfileLabel {
       position:relative;
       bottom: 35px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserTypeProfileLabelDocter {
       position:relative;
       bottom: 35px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserTypeProfileInput {
       position:relative;
       bottom: 95px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserTypeProfileInputDocter {
       position:relative;
       bottom: 110px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailProfileLable { 
       position:relative;
       bottom: 105px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailLabelDocterProfile {
       position:relative;
       bottom: 80px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailLabelLabProfile {
       position:relative;
       bottom: 75px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailProfileInput {
       position:relative;
       bottom: 130px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheEmailProfilePageInputDocter {
       position:relative;
       bottom: 110px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileLable {
       position:relative;
       bottom: 130px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileInput {
       position:relative;
       bottom: 150px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameProfileLable {
       position:relative;
       bottom: 155px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameProfileLableDoctor {
       position:relative;
       bottom: 141px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameProfileInput {
       position:relative;
       bottom: 180px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheUserNameProfileInputDoctor {
       position:relative;
       bottom: 170px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheMobileNumProfileLable {
       position:relative;
       top: 5px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheMobileNumProfileLableDoctor {
       position:relative;
       top: 18px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheMobileNumProfileInput {
       position:relative;
       bottom: 180px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheClinicNameProfileLabel {
       position:relative;
       bottom: 225px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheClinicNameProfileInput {
       position:relative;
       bottom: 250px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterProfileLabel {
       position:relative;
       bottom: 255px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheDocterProfileInput {
       position:relative;
       bottom: 280px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateProfileLabel {
       position:relative;
       bottom: 275px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateInputProfileLabel {
       position:relative;
       bottom: 290px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddresstProfileLabel {
       position:relative;
       bottom: 285px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddresstProfileLabelDoctor {
       position:relative;
       bottom: 242px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddresstInputProfile {
       position:relative;
       bottom: 300px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddresstInputProfileDoctor {
       position:relative;
       bottom: 270px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topThePageAccess {
       position:relative;
       /* bottom: 230px !important; */
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topThePageAccessPermissions {
       position:relative;
       /* bottom: 200px !important; */
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topThePageAccessPermissionsButtons {
       position:relative;
       /* bottom: 160px !important; */
    } 
}



@media (min-width: 1064px) and (max-width: 2500px) {
    .topTheAddressLabelDocter {
       position: relative !important;
       bottom: 0.2px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 2500px) {
    .topTheAddressInputDocter {
      position: relative !important;
      bottom: 4px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressLabelDocter {
       position: relative !important;
       bottom: 9.5px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressLabelDocter {
       position:relative;
       bottom: 280px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressInputDocter {
      position: relative !important;
      bottom: 13px !important;
    } 
} 

@media (min-width: 200px) and (max-width: 900px) {
    .topTheAddressInputDocter {
       position:relative;
       bottom: 300px !important;
    } 
}


@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheAddressLabel {
       position: relative !important;
       bottom: 14px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheAddressLabelEditView {
       margin-top: 3.5px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheAddressInputEditView {
       margin-top: -3px !important;
    } 
}


@media (min-width: 1083px) and (max-width: 1456px) {
    .topTheAddressLabel {
       position: relative;
       bottom:11px !important;
    } 
}

@media (min-width: 1083px) and (max-width: 1456px) {
    .topTheAddressLabelEditView {
       margin-top: 6px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1083px) {
    .topTheAddressLabelEditView {
       margin-top: 3px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1083px) {
    .topTheAddressInputEditView {
       margin-top: -3px !important;
    } 
}

@media (min-width: 997px) and (max-width: 1065px) {
    .topTheAddressLabel {
       position: relative !important;
       bottom: 23px !important;
    } 
}

@media (min-width: 900px) and (max-width: 997px) {
    .topTheAddressLabel {
       position: relative !important;
       bottom: 14px !important;
    } 
}

@media (min-width: 900px) and (max-width: 997px) {
    .topTheAddressInput {
      position: relative !important;
      bottom: 19px !important;
    } 
}

/* @media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressInput {
       margin-top: 3px !important;
    } 
} */

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressInputEditView {
       margin-top: -6px !important;
    } 
}

/* @media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressLabel {
       margin-top: 3px !important;
    } 
} */
@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddressLabelEditView {
       margin-top: 3px !important;
    } 
}

@media (min-width: 1083px) and (max-width: 1456px) {
    .topTheAddressInput {
       margin-top: -6px !important;
    } 
}

@media (min-width: 1083px) and (max-width: 1456px) {
    .topTheAddressInputEditView {
       margin-top: 1px !important;
    } 
}

@media (min-width: 1456px) and (max-width: 2500px) {
    .topTheAddressInput {
      position: relative !important;
      bottom: 19px !important;
    } 
}

@media (min-width: 1065px) and (max-width: 1083px) {
    .topTheAddressInput {
      position: relative !important;
      bottom: 18px !important;
    } 
}

@media (min-width: 997px) and (max-width: 1065px) {
    .topTheAddressInput {
      position: relative !important;
      bottom: 27px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1279px) {
    .topTheClinicNameInput {
       margin-top: -4px !important;
    } 
}

@media (min-width: 1204px) and (max-width: 1279px) {
    .topTheDocterProfileLabel {
       margin-top: -11px !important;
    } 
}

@media (min-width: 1204px) and (max-width: 1279px) {
    .topTheDocterProfileInput {
       margin-top: -11px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1204px) {
    .topTheDocterProfileLabel {
       margin-top: -3px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1204px) {
    .topTheDocterProfileInput {
       margin-top: -4px !important;
    } 
}


@media (min-width: 900px) and (max-width: 1203px) {
    .topTheDocterProfileIvrLabel {
       margin-top: -4px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1203px) {
    .topTheSateProfileLabel {
       margin-top: 3px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1203px) {
    .topTheSateInputProfileLabel {
       margin-top: 3px !important;
    } 
}

@media (min-width: 1065px) and (max-width: 1204px) {
    .topTheAddresstProfileLabel {
       position: relative !important;
      bottom: 5px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheAddresstProfileLabel {
       position: relative !important;
      bottom: 1px !important;
    } 
}

@media (min-width: 1203px) and (max-width: 2500px) {
    .topTheAddresstProfileLabelDoctor {
       position: relative !important;
       top: 5px !important;
    } 
}

@media (min-width: 1065px) and (max-width: 1204px) {
    .topTheAddresstProfileLabelDoctor {
       position: relative !important;
       top: 4px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1065px) {
    .topTheAddresstProfileLabelDoctor {
        position: relative !important;
        top: 5px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddresstProfileLabelDoctor {
        position: relative !important;
       bottom: 4px !important;
    } 
}

@media (min-width: 1203px) and (max-width: 2500px) {
    .topTheAddresstInputProfileDoctor {
        position: relative !important;
       top: 1px !important;
    } 
}

@media (min-width: 1065px) and (max-width: 1204px) {
    .topTheAddresstInputProfileDoctor {
        position: relative !important;
       top: 1px !important;
    } 
}

@media (min-width: 1065px) and (max-width: 1204px) {
    .topTheAddresstInputProfile {
        position: relative !important;
        bottom: 10px !important;
    } 
}

@media (min-width: 1062px) and (max-width: 1065px) {
    .topTheAddresstInputProfileDoctor {
        position: relative !important;
       bottom: 9px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1063px) {
    .topTheAddresstInputProfileDoctor {
        position: relative !important;
       bottom: 8px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheAddresstInputProfile {
        position: relative !important;
        bottom: 0px !important;
    } 
}


@media (min-width: 900px) and (max-width: 1064px) {
    .topTheClinicNameProfileLabel {
       margin-top: -10px !important;
    } 
}

@media (min-width: 1064px) and (max-width: 1279px) {
    .topTheClinicNameProfileLabel {
       margin-top: -3px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheClinicNameProfileInput {
       margin-top: -10px !important;
    } 
}

@media (min-width: 1030px) and (max-width: 1064px) {
    .topTheDocterProfileLabel {
       margin-top: -3px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1030px) {
    .topTheDocterProfileLabel {
       margin-top: 3px !important;
    } 
}


@media (min-width: 1030px) and (max-width: 1064px) {
    .topTheDocterProfileInput {
       margin-top: -6px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1030px) {
    .topTheDocterProfileInput {
       margin-top: 4px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheAddresstInputProfile {
       margin-top: -11px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1031px) {
    .topTheEmailProfileLable {
       margin-top: -1px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1031px) {
    .topTheEmailProfileInput {
       margin-top: -1px !important;
    } 
}


@media (min-width: 900px) and (max-width: 1031px) {
    .topTheAddresstProfileLabel {
       margin-top: 5px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1031px) {
    .topTheAddresstInputProfile {
       margin-top: -5px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1030px) {
    .topTheUserTypeProfileLabel {
       margin-top: 10px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1030px) {
    .topTheUserTypeProfileInput {
       margin-top: 10px !important;
    } 
}


/* City */

@media (min-width: 1204px) and (max-width: 1279px) {
    .topTheCityProfileLabelNew {
        position: relative !important;
        bottom: 2px !important;      
    } 
}


@media (min-width: 900px) and (max-width: 1204px) {
    .topTheCityProfileLabelNew {
        position: relative !important;
        bottom: 1px !important;      
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileLabelNew {
       position:relative;
       bottom: 275px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileLabelNewDoctor {
       position:relative;
       bottom: 205px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheCityProfileLabelNewDoctor {
       position:relative;
       bottom: 9px !important;
    } 
}




@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileInputNew {
       position:relative;
       bottom: 290px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityProfileInputNewDocter {
       position:relative;
       bottom: 233px !important;
    } 
}

@media (min-width: 900px) and (max-width: 1064px) {
    .topTheCityProfileInputNewDocter {
       position:relative;
       bottom: 9.5px !important;
    } 
}





@media (min-width: 1204px) and (max-width: 1279px) {
    .topTheCityProfileInputNew {
        position: relative !important;
        bottom: 5px !important;      
    } 
}

@media (min-width: 900px) and (max-width: 1204px) {
    .topTheCityProfileInputNew {
        position: relative !important;
        bottom: 4px !important;      
    } 
}

@media (min-width: 900px) and (max-width: 1065px) {
    .topTheCityLabelNew {
      position: relative !important;
      bottom: 15px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityLabelNew {
      position: relative !important;
      bottom: 240px !important;
    } 
}


@media (min-width: 900px) and (max-width: 1065px) {
    .topTheCityInputNew {
      position: relative !important;
      bottom: 15px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheCityInputNew {
      position: relative !important;
      bottom: 260px !important;
    } 
}


/* State */

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateLabelNew {
       position:relative;
       bottom: 100px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateInputLabelNew {
       position:relative;
       bottom: 120px !important;
    } 
}


@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateInputLabelNewProfile {
       position:relative;
       bottom: 130px !important;
    } 
}



@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateProfileLabelNew {
       position:relative;
       bottom: 130px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateProfileLabelNewDoctor {
       position:relative;
       bottom: 110px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheSateInputProfileLabelNew {
       position:relative;
       bottom: 150px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheLabelStateManageUserNew {
       position:relative;
       bottom: 100px !important;
    } 
}

@media (min-width: 200px) and (max-width: 900px) {
    .topTheInputStateManageUserNew {
       position:relative;
       bottom: 120px !important;
    } 
} 

.passwordAuto:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {   
    -webkit-box-shadow: 0 0 0 30px white inset !important;
        
    }



