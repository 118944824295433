.fitCenter {
    justify-content: center;
    align-items: center;
    display: flex;
   }
   .roundShape {
       height: 50px;;
       width:50px;
       background:#007E8566;
       border-radius: 30px;
   }
   
   .widthSize {
       width: 280px;
   }

   .Card_shadow{
    -webkit-box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important;
-moz-box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important;
box-shadow: 0px 5px 6px -1px rgba(0,0,0,0.21) !important;
transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}
.Card_shadow:hover {
    transform: scale(1.03);
  }