@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.heading{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root, .css-4jnixx-MuiStack-root>.MuiTextField-root{
    width: 135px;
    height: 35px;
}

.timepicker{
    width: 90px;
    height: 51px; 
}

@media (min-width: 1400px) and (max-width: 1900px) {
    .timepicker {
        width: 50%;
        height: 60px; 
    } 
}

@media (min-width: 1400px) {
    .timepicker{
        width: 70%;
        height: 60px; 
    } 
}

.labelgroup > label {
    padding: 3px 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.css-4jnixx-MuiStack-root {
    overflow: hidden !important;
  }

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 14px !important;
}

.css-1nshyrp-MuiStack-root>.MuiTextField-root{
    min-width: 135px !important;
}

.css-1nshyrp-MuiStack-root {
    width: 140px !important;
}